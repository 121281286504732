<template>
  <landing-central-card>
    <v-card class="reset-password-card flexcard" :elevation="elevation" :min-height="cardHeight" :style="cardStyles" flat>
      <v-card-loader v-if="loading"></v-card-loader>

      <v-card-text :class="this.$vuetify.breakpoint.name === 'xs'? 'pa-0':''">
        <v-form
            @keydown.enter.native="handleEnterKey"
            autocomplete="off"
            transition="slide-y-reverse-transition"
            ref="form"
            v-model="valid">
          <v-layout row wrap justify-center align-center>
            <v-flex xs12 sm11>
              <h1 class="starling-h1 mb-3">{{ $t('public.reset_password.title') }}</h1>

              <p class="starling-body"
                 v-if="$route.params.required"
                 v-html="$t('public.reset_password.content.required')">
              </p>
              <p class="starling-body"
                 v-else
                 v-html="$t('public.reset_password.content.default')">
              </p>
              <template
                  v-for="(ignored ,index) in $t('public.reset_password.content.common', {email:$store.state.auth.email_verification.username})">
                <p class="starling-body" :key="index"
                   v-html="$t('public.reset_password.content.common['+index+']', {email:$store.state.auth.email_verification.username})">
                </p>
              </template>

            </v-flex>
            <v-flex xs12 sm11>
              <h4 class="text-left starling-body mt-4 font-weight-medium">
                {{ $t('public.reset_password.fields.code.title') }}
              </h4>
              <registration-field-verify-code class="mt-2"
                                              v-model="code"
                                              :error="error"
                                              @input="error=null;"
                                              @form-validate="onFormValidate"/>
            </v-flex>

            <v-flex xs12 sm11>
              <h4 class="text-left starling-body mt-4 font-weight-medium">
                {{ $t('public.reset_password.fields.password.title') }}
              </h4>
              <registration-field-password class="mt-2"
                                           v-model="newPassword"
                                           :confirmation="newPasswordConfirmation"
                                           @form-validate="onFormValidate"/>
            </v-flex>
            <v-flex xs12 sm11>
              <registration-field-password-confirmation class="mt-2"
                                                        v-model="newPasswordConfirmation"
                                                        :password="newPassword"
                                                        @form-validate="onFormValidate"/>
            </v-flex>
          </v-layout>
        </v-form>

      </v-card-text>

      <v-card-actions justify-center align-center>
        <v-layout row wrap justify-center align-center>
          <v-flex xs12 class="text-center">
            <primary-button
                id="btn-do-reset-password"
                :disabled="!valid || loading"
                @click="executePasswordReset"
                class="mt-3"
                large>
              {{ $t('public.reset_password.buttons.set_password') }}
            </primary-button>
          </v-flex>
        </v-layout>
      </v-card-actions>

      <v-card-actions justify-center align-center>
        <v-layout row wrap justify-center align-center>
          <v-flex xs12 class="text-center"
                  justify-center align-center text-center>
            <v-btn
                id="btn-cancel"
                :disabled="loading"
                @click="cancel"
                flat>
              {{ $t('common.actions.cancel') }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-actions>
    </v-card>
  </landing-central-card>
</template>

<script>
import vCardLoader from '@/views/components/loader/v-card-loader.vue';
import LandingCentralCard from '@/views/landing/landing-central-card.vue';
import RegistrationFieldPassword from '@/views/landing/register/components/registration-field-password.vue';
import PrimaryButton from '@/views/components/button/primary-button.vue';
import RegistrationFieldPasswordConfirmation
  from '@/views/landing/register/components/registration-field-password-confirmation.vue';
import RegistrationFieldVerifyCode from '@/views/landing/register/components/registration-field-verify-code.vue';
import { Auth } from 'aws-amplify';
import { mapState } from 'vuex';

export default {
  components: {
    RegistrationFieldVerifyCode,
    RegistrationFieldPassword,
    RegistrationFieldPasswordConfirmation,
    PrimaryButton,
    LandingCentralCard,
    vCardLoader,
  },
  props: [ 'elevation', 'cardHeight', 'cardStyles' ],
  data() {
    return {
      loading: false,
      valid: false,
      code: null,
      newPassword: null,
      newPasswordConfirmation: null,
      error: null,
    };
  },
  computed: {
    ...mapState({
      partner: state => {
        return state.identity;
      },
      locale() {
        return localStorage.getItem('currLang');
      },
    }),
  },
  beforeCreate() {
    if (!this.$store.state.auth.email_verification || !this.$store.state.auth.email_verification.username) {
      this.$router.replace({ name: 'sign_in' });
    }
  },
  methods: {
    executePasswordReset() {
      this.loading = true;
      return this.$store.dispatch('identity/confirmForgotPassword', {
        username: this.$store.state.auth.email_verification.username,
        password: this.newPassword,
        confirmationCode: this.code,
        clientMetadata: {
          subdomain: this.partner?.subdomain,
          locale: this.locale,
        },
      }).then(res => {
        this.$log.info('password reset', res);
        if (!res.code) {
          return this.$store.dispatch('identity/login', {
            username: this.$store.state.auth.email_verification.username,
            password: this.newPassword,
          }).then(loginResult => {
            this.$log.debug('Login result', loginResult);
            if (!loginResult || !loginResult.userIdentity || !loginResult.userIdentity.location || !loginResult.userIdentity.location['@type']) {
              throw new Error('Cannot determine user');
            }
            if (loginResult.userIdentity.location['@type'] === 'nowhere') {
              return this.$store.dispatch('identity/checkReferral', { email: loginResult.userIdentity.username }).then(refIdentity => {
                if (refIdentity) {
                  return this.$router.replace({
                    name: 'referral_register',
                    query: { referral_key: refIdentity.referralKey },
                  });
                }
                return this.$router.replace({ name: 'setup_access_code' });
              });
            } else if (loginResult.userIdentity.location['@type'] === 'database') {
              if (!loginResult.redirect) {
                this.$router.replace({ name: 'home' });
              }
            }
          }, err => {
            this.$log.error(err);
            if (err && err.code === 'UserNotConfirmedException') {
              return Auth.resendSignUp(this.username, {
                subdomain: this.partner?.subdomain,
                locale: this.locale,
              }).then(res => {
                this.$store.state.auth.email_verification.username = this.username;
                this.$store.state.auth.email_verification.password = this.password;
                return this.$router.push({ name: 'email_verification' });
              });
            } else if (err && err.code === 'PasswordResetRequiredException') {
              this.$store.state.auth.email_verification.username = this.username;
              this.$store.state.auth.email_verification.password = this.password;
              return this.$store.dispatch('identity/forgotPassword', {
                username: this.username,
                clientMetadata: {
                  subdomain: this.partner?.subdomain,
                  locale: this.locale,
                },
              }).then(() => {
                return this.$router.push({ name: 'reset_password', params: { required: 'true' } });
              });
            }
            this.error = this.$t('public.signin.error.message');
          });
        }
        this.$log.error('forgot password error', res.code, res);
        if (res.code && res.code === 'CodeMismatchException') {
          this.error = 'MISMATCH';
        } else if (res.code && res.code === 'ExpiredCodeException') {
          this.error = 'EXPIRED';
        } else if (res.code && res.code === 'LimitExceededException') {
          this.error = 'LIMIT_EXCEEDED';
        } else {
          this.error = 'GENERIC';
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    handleEnterKey() {
      if (this.valid) {
        this.executePasswordReset();
      }
    },
    onFormValidate(fieldname) {
      if (this.$refs.form) {
        if (fieldname) {
          const field = this.$refs.form.$data.inputs.find(i => i.$attrs.name === fieldname);
          if (field) {
            field.validate(true);
          }
        } else {
          this.$refs.form.validate();
        }
      }
    },
    cancel() {
      this.$router.push({ name: 'presentation' });
    },
  },
};
</script>

<style scoped>
.reset-password-card {
  border-radius: 15px;
}
</style>
