<template>
  <v-layout row wrap>
    <v-flex xs12 style="max-width:220px;" class="mt-4">
      <v-spacer/>
      <v-text-field
          ref="field"
          box
          maxlength="6"
          class="verification-code"
          @keypress="numberOnly"
          v-model="innerValue"
          autofocus
          autocomplete="off"
          :error="!!error"
          :error-messages="!!error ? [$t('public.email_verification.error.'+error)]:[]"
          :success="!!success"
          :success-messages="!!success ? [$t('public.email_verification.success.'+success)]:[]"
          @paste="handlePastedValues"
      />
      <v-spacer/>
    </v-flex>
  </v-layout>
</template>
<script>

export default {
  name: 'registration-field-verify-code',
  props: {
    value: String,
    error: String,
    success: String,
  },
  data() {
    return {
      innerValue: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.innerValue = newValue;
    },
    innerValue(newInnerValue) {
      this.$emit('input', newInnerValue);
    },
  },
  methods: {
    numberOnly(val) {
      if (isNaN(Number(val.key))) {
        return val.preventDefault();
      }
    },
    handlePastedValues(event) {
      const pastedValue = event.clipboardData.getData('text/plain');
      let canPaste = true;
      const regx = new RegExp(`^\\d{6}$`);
      canPaste = regx.test(pastedValue);
      if (canPaste) {
        this.innerValue = pastedValue;
      }
    },
  },
};
</script>

<style lang="scss">
.verification-code {
  text-align: center;
  max-width: 220px !important;
}

.verification-code input {
  text-align: center;
  font-size: 35px;
  letter-spacing: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.verification-code.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  transition: none;
}

.verification-code.theme--light.v-text-field:not(.error--text) > .v-input__control > .v-input__slot:hover:before,
.verification-code.theme--light.v-text-field:not(.error--text) > .v-input__control > .v-input__slot:focus:before,
.verification-code.theme--light.v-text-field:not(.error--text) > .v-input__control > .v-input__slot:not(:focus):before {
  border-color: var(--StarlingPrimary2);
}

.verification-code.theme--light.v-label {
  color: var(--StarlingAlmostBlack);
}

.verification-code.theme--light.v-input:not(.v-input--is-disabled) input {
  color: var(--StarlingAlmostBlack);
}

.verification-code.typed {
  border-bottom: 3px solid var(--StarlingPrimary2);
}

.verification-code.error {
  background-color: transparent !important;
  color: var(--StarlingError);
  border-bottom: 3px solid var(--StarlingError);
}
.verification-code.success {
  background-color: transparent !important;
  color: var(--StarlingSuccess);
  border-bottom: 3px solid var(--StarlingSuccess);
}
</style>
