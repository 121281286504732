var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "", wrap: "" } },
    [
      _c(
        "v-flex",
        {
          staticClass: "mt-4",
          staticStyle: { "max-width": "220px" },
          attrs: { xs12: "" }
        },
        [
          _c("v-spacer"),
          _c("v-text-field", {
            ref: "field",
            staticClass: "verification-code",
            attrs: {
              box: "",
              maxlength: "6",
              autofocus: "",
              autocomplete: "off",
              error: !!_vm.error,
              "error-messages": !!_vm.error
                ? [_vm.$t("public.email_verification.error." + _vm.error)]
                : [],
              success: !!_vm.success,
              "success-messages": !!_vm.success
                ? [_vm.$t("public.email_verification.success." + _vm.success)]
                : []
            },
            on: { keypress: _vm.numberOnly, paste: _vm.handlePastedValues },
            model: {
              value: _vm.innerValue,
              callback: function($$v) {
                _vm.innerValue = $$v
              },
              expression: "innerValue"
            }
          }),
          _c("v-spacer")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }