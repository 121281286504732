var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "landing-central-card",
    [
      _c(
        "v-card",
        {
          staticClass: "reset-password-card flexcard",
          style: _vm.cardStyles,
          attrs: {
            elevation: _vm.elevation,
            "min-height": _vm.cardHeight,
            flat: ""
          }
        },
        [
          _vm.loading ? _c("v-card-loader") : _vm._e(),
          _c(
            "v-card-text",
            { class: this.$vuetify.breakpoint.name === "xs" ? "pa-0" : "" },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: {
                    autocomplete: "off",
                    transition: "slide-y-reverse-transition"
                  },
                  nativeOn: {
                    keydown: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleEnterKey($event)
                    }
                  },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        wrap: "",
                        "justify-center": "",
                        "align-center": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm11: "" } },
                        [
                          _c("h1", { staticClass: "starling-h1 mb-3" }, [
                            _vm._v(
                              _vm._s(_vm.$t("public.reset_password.title"))
                            )
                          ]),
                          _vm.$route.params.required
                            ? _c("p", {
                                staticClass: "starling-body",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t(
                                      "public.reset_password.content.required"
                                    )
                                  )
                                }
                              })
                            : _c("p", {
                                staticClass: "starling-body",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t(
                                      "public.reset_password.content.default"
                                    )
                                  )
                                }
                              }),
                          _vm._l(
                            _vm.$t("public.reset_password.content.common", {
                              email:
                                _vm.$store.state.auth.email_verification
                                  .username
                            }),
                            function(ignored, index) {
                              return [
                                _c("p", {
                                  key: index,
                                  staticClass: "starling-body",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$t(
                                        "public.reset_password.content.common[" +
                                          index +
                                          "]",
                                        {
                                          email:
                                            _vm.$store.state.auth
                                              .email_verification.username
                                        }
                                      )
                                    )
                                  }
                                })
                              ]
                            }
                          )
                        ],
                        2
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm11: "" } },
                        [
                          _c(
                            "h4",
                            {
                              staticClass:
                                "text-left starling-body mt-4 font-weight-medium"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "public.reset_password.fields.code.title"
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          ),
                          _c("registration-field-verify-code", {
                            staticClass: "mt-2",
                            attrs: { error: _vm.error },
                            on: {
                              input: function($event) {
                                _vm.error = null
                              },
                              "form-validate": _vm.onFormValidate
                            },
                            model: {
                              value: _vm.code,
                              callback: function($$v) {
                                _vm.code = $$v
                              },
                              expression: "code"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm11: "" } },
                        [
                          _c(
                            "h4",
                            {
                              staticClass:
                                "text-left starling-body mt-4 font-weight-medium"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "public.reset_password.fields.password.title"
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          ),
                          _c("registration-field-password", {
                            staticClass: "mt-2",
                            attrs: {
                              confirmation: _vm.newPasswordConfirmation
                            },
                            on: { "form-validate": _vm.onFormValidate },
                            model: {
                              value: _vm.newPassword,
                              callback: function($$v) {
                                _vm.newPassword = $$v
                              },
                              expression: "newPassword"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm11: "" } },
                        [
                          _c("registration-field-password-confirmation", {
                            staticClass: "mt-2",
                            attrs: { password: _vm.newPassword },
                            on: { "form-validate": _vm.onFormValidate },
                            model: {
                              value: _vm.newPasswordConfirmation,
                              callback: function($$v) {
                                _vm.newPasswordConfirmation = $$v
                              },
                              expression: "newPasswordConfirmation"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { attrs: { "justify-center": "", "align-center": "" } },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    row: "",
                    wrap: "",
                    "justify-center": "",
                    "align-center": ""
                  }
                },
                [
                  _c(
                    "v-flex",
                    { staticClass: "text-center", attrs: { xs12: "" } },
                    [
                      _c(
                        "primary-button",
                        {
                          staticClass: "mt-3",
                          attrs: {
                            id: "btn-do-reset-password",
                            disabled: !_vm.valid || _vm.loading,
                            large: ""
                          },
                          on: { click: _vm.executePasswordReset }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "public.reset_password.buttons.set_password"
                                )
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { attrs: { "justify-center": "", "align-center": "" } },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    row: "",
                    wrap: "",
                    "justify-center": "",
                    "align-center": ""
                  }
                },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "text-center",
                      attrs: {
                        xs12: "",
                        "justify-center": "",
                        "align-center": "",
                        "text-center": ""
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            id: "btn-cancel",
                            disabled: _vm.loading,
                            flat: ""
                          },
                          on: { click: _vm.cancel }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.actions.cancel")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }