import { render, staticRenderFns } from "./ResetPassword.vue?vue&type=template&id=247d7310&scoped=true&"
import script from "./ResetPassword.vue?vue&type=script&lang=js&"
export * from "./ResetPassword.vue?vue&type=script&lang=js&"
import style0 from "./ResetPassword.vue?vue&type=style&index=0&id=247d7310&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "247d7310",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VFlex,VForm,VLayout})


/* hot reload */
if (module.hot) {
  var api = require("/opt/jenkins/workspace/starling-app-dev-ci/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('247d7310')) {
      api.createRecord('247d7310', component.options)
    } else {
      api.reload('247d7310', component.options)
    }
    module.hot.accept("./ResetPassword.vue?vue&type=template&id=247d7310&scoped=true&", function () {
      api.rerender('247d7310', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/landing/reset_password/ResetPassword.vue"
export default component.exports